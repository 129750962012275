import { House } from "@gravity-ui/icons"
import { Icon, useLayoutContext } from "@gravity-ui/uikit"
import Image from "components/ImageItem"
import { GalleryQuery, useGalleryQuery } from "queries"
import { createElement as $, FC } from "react"
import { useNavigate } from "react-router-dom"
import { DataProps } from ".."
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import './style.scss'

const Gallery: FC<DataProps> = ({ id }) => {
  const { data, loading } = useGalleryQuery({ 
    variables: { ziferblatId: id }, 
    fetchPolicy: 'cache-first' 
  })
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const component = isMobile ? Mobile : Desktop
  
  if (!data?.gallery || loading) return null

  const gallery = [...data.gallery].sort((a, b) => {
    if (a.location === b.location) return 0
    if (a.location === 'hero') return -1
    return 1
  })

  return $(component, { gallery })
}

export const ImageItemIteratee = (props: ImageProps) => 
  $(ImageItem, { key: props.id, ...props })

export enum MediaLocationEnum {
	Gallery = 'gallery',
	Hero = 'hero',
}

const ImageItem: FC<ImageProps> = ({ id, media, location }) => {
  const navigate = useNavigate()

  if (!media) return null
  
  return $('div', { style: { position: 'relative' } }, 
    $(Image, { 
      key: id,
      width: 300,
      id: media.id,
      className: 'gallery-image-item', 
      onClick: () => navigate(`/settings/gallery/${id}`),
    }), 
    location === MediaLocationEnum.Hero && 
      $('div', { className: 'location-item' }, 
        $(Icon, { data: House }))
  )
}

type ImageProps = GalleryQuery['gallery'][0]

export default Gallery