import { useApolloClient } from "@apollo/client";
import { TrashBin } from "@gravity-ui/icons";
import { Button, ButtonProps, Flex, Icon, spacing } from "@gravity-ui/uikit";
import ConfirmDelete from "components/ConfirmDelete";
import Image from "components/ImageItem";
import { ImageQuery, useRemoveFromGalleryMutation } from "queries";
import { createElement as $, FC } from "react";
import SelectLocation from "./SelectLocation";
import './style.scss'

const Desktop: FC<ImageQuery> = (data) => { 
  const [mutate, { loading }] = useRemoveFromGalleryMutation({ variables: { id: data.galleryItem!.id }})
  const client = useApolloClient()
  const onDelete = () => mutate().then(() => client.reFetchObservableQueries())

  if (!data.galleryItem) return null

  return $(Flex, { 
    direction: 'column', 
    alignItems: 'flex-start',
    gap: 4,
    className: spacing({ p: 4 })
    },
    $(Image, { 
      id: data.galleryItem.media.id, 
      width: 500,
      className: 'gallery-item'
    }),
    $(Flex, { width: '100%' },
      $(SelectLocation, data),
      $(ConfirmDelete, { 
        trigger: RemoveButton, 
        onDelete, 
        loading,
        title: 'delete.image'
      }),
    ))
}

const RemoveButton: FC<ButtonProps> = ({ onClick, loading }) => 
  $(Button, { 
    view: 'outlined',
    pin: 'clear-round',
    size: 'xl',
    loading,
    onClick,
  }, $(Icon, { data: TrashBin }))

export default Desktop