import { Link } from "@gravity-ui/uikit";
import { createElement as $ } from "react";
import { FormattedMessage } from "react-intl";

const MarkdownNote = () => 
  $(FormattedMessage, {
    id: 'forms.note.markdown', 
    values: {
      link: $(Link, { 
        key: 'description',
        href: 'https://www.markdownguide.org/cheat-sheet', 
        target: '_blank' 
      }, $(FormattedMessage, ({ id: 'forms.markdown' })))
  }})

export default MarkdownNote