import DateField from 'components/FormikFields/Date'
import TextField from 'components/FormikFields/TextField'
import FormRow from 'components/FormRow'
import MarkdownNote from 'components/MarkdownNote'
import { Formik, FormikValues } from 'formik'
import { useUpsertZiferblatI18nMutation, useZiferblatDescriptionQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { DataProps } from '..'
import Container from '../Container'
import SaveButton from '../SaveButton'
import { useLayoutContext } from '@gravity-ui/uikit'

const Description: FC<DataProps> = ({ id }) => {
  const { data, loading } = useZiferblatDescriptionQuery({ variables: { id }})
  const [mutate] = useUpsertZiferblatI18nMutation()
  const { locale } = useIntl()
  const { activeMediaQuery } = useLayoutContext()
  const direction = activeMediaQuery === 's' ? 'column' : 'row'
  const ziferblat = data?.ziferblat

  if (!ziferblat || loading) return

  const i18n = ziferblat.i18n[0]

  const initialValues = {
    name: i18n.name,
    description: i18n.description,
    rules: i18n.rules,
    activeFrom: ziferblat.activeFrom
  }

  const onSubmit = ({ name, description, rules }: FormikValues) => mutate({
    updateQueries: {
      ZiferblatDescription: (prev, { mutationResult }) => {
        const prevData = prev.ziferblat.i18n[0]
        const newData = mutationResult.data?.insertZiferblatI18n
        const mergedI18n = { ...prevData, ...newData }
        const result = { ziferblat: {...prev.ziferblat, i18n: [mergedI18n] }}
        
        return result
      },
    },
    variables: {
      data: { 
        name,
        description,
        rules,
        ziferblatId: id,
        locale
      },
    }})

  return $(Formik, { 
    initialValues, 
    onSubmit, 
    enableReinitialize: true, 
    validateOnBlur: true
    },
    $(Container, null,
      $(FormRow, { label: 'ziferblat.form.name', direction },
        $(TextField, { name: 'name' })),
      $(FormRow, { label: 'ziferblat.form.description', direction },
        $(TextField, { name: 'description', note: $(MarkdownNote) })),
      $(FormRow, { label: 'ziferblat.form.rules', direction },
        $(TextField, { name: 'rules' })),
      $(FormRow, { label: 'ziferblat.form.activeFrom', direction },
        $(DateField, { name: 'activeFrom', disabled: true })),
      $(SaveButton)))
}

export default Description
