import { useApolloClient } from '@apollo/client'
import { ChevronDown, TrashBin } from '@gravity-ui/icons'
import { Button, ButtonProps, Flex, Icon, Sheet, spacing } from '@gravity-ui/uikit'
import ConfirmDelete from 'components/ConfirmDelete'
import { MessageIds } from 'components/IntlProvider'
import useFab from 'hooks/useFab'
import MobileFabContext from 'pages/Settings/Mobile/MobileFabContext'
import { ImageQuery, useRemoveFromGalleryMutation, useUpdateMediaLocationMutation } from 'queries'
import { createElement as $, FC, Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MediaLocationEnum } from '..'
import '../style.scss'
import Image from 'components/ImageItem'

const Mobile: FC<ImageQuery> = (data) => {
  useFab($(Fab), MobileFabContext)

  if (!data || !data.galleryItem) return null
  return $(Flex, { 
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    className: spacing({ p: 4 }),
    gap: 2,
    },
    $(Image, {
      id: data.galleryItem?.media.id,
      width: 500,
      className: 'gallery-image',
    }),
    $(ImageOptions, data))
}

const Fab = () => 
  $(Button, { 
    view: 'outlined',
    size: 'l',
    width: 'max',
    pin: 'clear-round'
  })

const ImageOptions: FC<ImageQuery> = ({ galleryItem }) => {
  const [open, setOpen] = useState(false)
  const [mutate, { loading }] = useUpdateMediaLocationMutation()
  const intl = useIntl()

  const options = Object.values(MediaLocationEnum).map((value) => {
    const content = intl.formatMessage({ id: `settings.tabs.gallery.location.${value}` })
    return { value, content }
  })
  const currentLocation = options.find((option) => option.value === galleryItem?.location)
  
  const handleClick = (value: string) => mutate({
    updateQueries: {
      Image: (prev, { mutationResult }) => {
        const location = mutationResult.data?.updateZiferblatGalleryByPk?.location
        const updated = { galleryItem: { ...prev.galleryItem, location }}
        return updated
      }
    },
    variables: { 
      id: galleryItem!.id, 
      location: value 
    }})

  if (!galleryItem) return null
  return $(Fragment, null, 
    $(Button, {
      onClick: () => setOpen(true),
      width: 'max',
      size: 'xl',
      view: 'outlined',
    }, 
    $(Flex, { gap: 2, alignItems: 'center' }, 
      intl.formatMessage({ id: `settings.tabs.gallery.location.${currentLocation?.value}` as MessageIds }),
      $(Icon, { data: ChevronDown }))),
    $(Sheet, { visible: open, onClose: () => setOpen(false) },
      $(Flex, { direction: 'column', gap: 2, className: spacing({ p: 2 }) },
        options.map((option) => 
          $(Button, {
            key: option.value, 
            selected: option.value === galleryItem?.location,
            width: 'max', 
            size: 'l',
            onClick: () => handleClick(option.value), 
            loading 
            }, option.content)),
        $(Delete, { id: galleryItem.id })
    )))
}

const Delete: FC<{ id: string }> = ({ id }) => {
  const [mutate, { loading }] = useRemoveFromGalleryMutation({ variables: { id }})
  const client = useApolloClient()
  const onDelete = () => mutate().then(() => client.reFetchObservableQueries())

  return $(ConfirmDelete, {
    trigger: RemoveButton,
    onDelete,
    loading
  })
}

const RemoveButton: FC<ButtonProps> = ({ onClick, loading }) =>   
  $(Button, {
    width: 'max',
    view: 'outlined-danger', 
    size: 'l',
    loading,
    onClick,
  }, 
  $(Icon, { data: TrashBin }),
  $(FormattedMessage, { id: 'delete' })
)

export const getImageUrl = (id: string, width: number, height?: number) =>
  `${mediaHost}/resized/${width}/${height ? `${height}/` : ''}${id}`
 
export const getOriginalImageUrl = (id: string) =>
   `${mediaHost}/${id}`

const mediaHost = 'https://media.ziferblat.net'

export default Mobile