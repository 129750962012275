import PersonPlusIcon from '@gravity-ui/icons/PersonPlus'
import CloseIcon from '@gravity-ui/icons/Xmark'
import { Button, Icon, Modal, TextInput, spacing } from '@gravity-ui/uikit'
import Empty from 'components/Empty'
import { gridLoadingElements } from 'components/LoadingElements/LoadingElements'
import useFab from 'hooks/useFab'
import { CheckinsQuery } from 'queries'
import { createElement as $, FC, Fragment, PropsWithChildren, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom'
import { CheckinsProps } from '.'
import CheckinListItemIteratee from './CheckinListItem'
import { GroupIteratee } from './CheckinsGroup'
import AddCheckin from './Forms/AddCheckin'
import ViewCheckin from './Forms/ViewCheckin'
import groupCheckinsByEvents from './groupedCheckins'

const Desktop: FC<CheckinsProps> = ({ data, loading }) => {
  const match = useMatch('/checkins/:page')
  const isFormOpen = !!match?.params.page
  const navigate = useNavigate()
  useFab($(Add))

  if (!data) return 'no data'
  return $(Fragment, null,
    $(Modal, { open: isFormOpen, onClose: () => navigate('/checkins') },
      $('div', { className: spacing({ p: 4 })},
        $(Routes, null,
          data &&
            $(Route, {
              path: ':id',
              element: $(ViewCheckin, data)
              }),
          $(Route, {
            path: 'new',
            element: $(AddCheckin, data)
            })))),
    loading 
      ? gridLoadingElements 
      : $(Content, data))
}

const Content: FC<CheckinsQuery> = (props) => {
  const intl = useIntl()
  const [search, setSearch] = useState('')
  const groups = groupCheckinsByEvents(intl, search, props.checkins)

  if (!search && groups.length === 0)
    return $(Empty)

  return $(Fragment, null,
    $(Search, { search, setSearch }),
    groups.length === 1 && groups[0].name === intl.formatMessage({ id: 'checkins.eventless' })
      ? $(CheckinsContainer, null,
          groups[0].checkins.map(CheckinListItemIteratee))
      : groups.map(GroupIteratee))
}

const Search: FC<SearchProps> = (props) => {
  const match = useMatch('/checkins/:page')
  if (match) return null

  if (!props.search)
    return $(SearchListener, props)

  return $(SearchInput, props)
}

const SearchListener: FC<SearchProps> = ({ setSearch }) => {
  useFab($(Add)) // This is required to get back to the original state
  useEffect(() => {
    const eventListener = (event: KeyboardEvent) => {
      if (event.key.length === 1 && !event.metaKey && !event.ctrlKey)
        setSearch(event.key)
    }
    window.addEventListener('keyup', eventListener)
    return () => window.removeEventListener('keyup', eventListener)
  }, [setSearch])
  return null
}

const SearchInput: FC<SearchProps> = ({
  search,
  setSearch
}) => {
  useFab($(Button, {
    size: 'xl',
    view: 'outlined',
    onClick: () => setSearch('')
    },
    $(Icon, { data: CloseIcon, size: 24 })))
  
  return $('div', { className: spacing({ mb: 4 })},
    $(TextInput, {
      autoFocus: true,
      value: search,
      size: 'xl',
      onUpdate: setSearch
    }))
}

const Add = () => {
  const navigate = useNavigate()
  return $(Button, {
    size: 'xl',
    view: 'action',
    onClick: () => navigate('/checkins/new', { replace: true })
    },
    $(Icon, { data: PersonPlusIcon }))
}

export const CheckinsContainer: FC<PropsWithChildren> = ({ children }) =>
  $('div', { style: CheckinsContainerStyles, children })

const CheckinsContainerStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(10rem, 1fr))',
  gridAutoRows: '100px',
  gap: 8
}

type SearchProps = {
  search: string,
  setSearch: React.Dispatch<React.SetStateAction<string>>
}

export default Desktop