import { Button, useLayoutContext } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useMatch } from 'react-router-dom'
import { Props } from '.'
import './style.scss'

const MenuIteratee = (route: Props['routes'][number]) =>
  $(MenuItem, { key: route, route })

const MenuItem: FC<{ route: Props['routes'][number] }> = ({ route }) => {
  const match = useMatch<'page', '/settings/:page/*'>('/settings/:page/*')
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'

  return $(Link, { to: `/settings/${route}` },
    $(Button, {
      selected: match?.params.page === route,
      view: 'flat',
      width: 'max',
      className: `menu-item_${isMobile ? 'mobile' : 'desktop'}`
      },
      // $(Text, { color: 'hint' }, 
      //   $(Icon, { data: pages[route].icon })),
      $(FormattedMessage, { id: `settings.tabs.${route}` })))
}

export default MenuIteratee