
import { Flex, useLayoutContext } from "@gravity-ui/uikit"
import FormControls from "components/FormControls/FormControls"
import SaveButton from "pages/Settings/SaveButton"
import { RoomQuery, useRoomQuery } from "queries"
import { createElement as $, FC } from "react"
import { useParams } from "react-router-dom"
import Images from "./Images"
import RoomForm from "./RoomForm"
import RoomWithData from "./RoomWithData"

const Room = () => {
  const params = useParams<'id'>()
  const id = params.id!
  const { data } = useRoomQuery({ variables: { id }})
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop 

  if (!data) return null
  return $(RoomWithData, data, $(component, data))
}

const Desktop: FC<RoomQuery> = (data) =>
  $(Flex, { gap: 4, width: '100%' }, 
    $(Flex, { direction: 'column', minWidth: '400px' },
      $(RoomForm),
      $(SaveButton)),
    $(Images, data))

const Mobile: FC<RoomQuery> = (data) => 
  $(Flex, { 
    direction: 'column', 
    gap: 3,
    },
    $(Images, data),
    $(RoomForm),
    $(FormControls, { title: 'settings.tabs.rent' }))

export default Room