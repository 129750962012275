import { Card, Flex, spacing, useLayoutContext } from '@gravity-ui/uikit'
import { createElement as $, FC, PropsWithChildren } from 'react'

const Container: FC<PropsWithChildren> = ({ children }) => {
	const { activeMediaQuery } = useLayoutContext()

	if (activeMediaQuery === 's') 
    return $(Flex, { direction: 'column' }, children)
  
	return $(Card, {
		view: 'filled',
		className: spacing({ p: 4 }),
		style: { 
      maxWidth: '500px',
      flexGrow: 1
    },
		children,
	})
}

export default Container
