import { Book, FolderHouse, MapPin, PencilToLine, PersonWorker, Picture, ScalesUnbalanced, TagRuble } from '@gravity-ui/icons'
import { useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { createElement as $, useContext } from 'react'
import { Navigate, Route, Routes, useMatch } from 'react-router-dom'
import Address from './Address'
import Contacts from './Contacts'
import Description from './Description'
import Desktop from './Desktop'
import Gallery from './Gallery'
import Legal from './Legal'
import Mobile from './Mobile'
import Rent from './Rent'
import Staff from './Staff'
import Tariff from './Tariff'

const Settings = () => {
	const { activeMediaQuery } = useLayoutContext()
	const component = activeMediaQuery === 's' ? Mobile : Desktop
  const { ziferblatId } = useContext(ZiferblatContext)
  const match = useMatch<'page', '/settings/:page'>('/settings/:page');
	const active = match?.params.page as SettingsRoute

	return $(Routes, null,
		$(Route, { path: '*', element: $(component, { routes, active }) },
			routes.map((path) =>
				$(Route, {
					key: path,
					path: `${path}/*`,
					element: $(pages[path].component, { id: ziferblatId })
				})),
      $(Route, { path: '*', element: $(Navigate, { to: `/settings/${routes[0]}` }) })))
}

export const pages = {
  description: { 
    component: Description,
    icon: PencilToLine
  },
  address: { 
    component: Address,
    icon: MapPin
  },
  rent: { 
    component: Rent,
    icon: FolderHouse
  },
  gallery: { 
    component: Gallery,
    icon: Picture
  },
  tariff: { 
    component: Tariff,
    icon: TagRuble
  },
  staff: {
    component: Staff,
    icon: PersonWorker
  },
  contacts: {
    component: Contacts,
    icon: Book
  },
  legal: {
    component: Legal,
    icon: ScalesUnbalanced
  }
}
  
const routes = Object.keys(pages) as SettingsRoute[]

export type SettingsRoute = (keyof typeof pages)
export type Props = {
	routes: SettingsRoute[]
	active: SettingsRoute
}
export type DataProps = { id: string }

export default Settings