import { Flex } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Props } from '.'
import MenuIteratee from './MenuIteratee'
import './style.scss'

const Desktop: FC<Props> = ({ routes }) =>
  $(Flex, { gap: 4 },
    $(Flex, {
      direction: 'column',
      gap: 3,
      className: 'menu'
      },
      routes.map(MenuIteratee)),
    $('div', { className: 'outlet' },
      $(Outlet)))
      
export default Desktop