import { Persons } from '@gravity-ui/icons'
import { Card, CardProps, Flex, Icon, Label, Text } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import { BookingStatusEnum, CulturalEventStatusEnum } from 'queries'
import { createElement as $, FC, Fragment, PropsWithChildren } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Booking, CalendarEvent, CulturalEvent } from './groupCalendarEvents'
import './style.scss'

const CalendarEventListItemIteratee = ({ booking, culturalEvent }: CalendarEvent) => {
  if (booking) 
    return $(BookingEventItem, { key: booking.id, ...booking })
  if (culturalEvent) 
    return $(CulturalEventItem, { key: culturalEvent.id, ...culturalEvent })
}

const BookingEventItem: FC<Booking> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  if (!props) return null

  const { id, name, startsAt, status, guestsCount } = props

  return $('div', {
    onClick: () => navigate(`/calendar/booking/${id}`),
    style: { cursor: 'pointer' },
    },
    $(EventCard, {
      theme: 'info',
      children: $(Fragment, null,
        $(Flex, { direction: 'column' },
          $(Text, { 
            variant: 'body-2',
            ellipsisLines: 2,
            style: { alignSelf: 'flex-start' }
            },
            name || intl.formatMessage({ id: 'calendarEvent.noName' })),
          $(EventDate, { status, startsAt })),
        $(Flex, { gap: 1 },
          status && $(StatusChip, { status }),
          guestsCount && guestsCount > 1 && $(GuestsChip, { guestsCount })))
    }))
}

const CulturalEventItem: FC<CulturalEvent> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  if (!props) return null

  const { id, title, startsAt, endsAt, status } = props

  return $(EventCard, {
    type: 'action',
    onClick: () => navigate(`/calendar/${id}`),
    children: $(Fragment, null,
      $(Flex, { direction: 'column' },
        $(Text, { 
          variant: 'body-2',
          ellipsisLines: 4,
          style: { alignSelf: 'flex-start' }
          }, 
          title || intl.formatMessage({ id: 'calendarEvent.noName' })),
        $(EventDate, { status, startsAt, endsAt })),
      status && $(StatusChip, { status })),
  })
}

const EventCard: FC<CardProps & PropsWithChildren> = ({ 
  children,
  ...props
}) => $(Card, {
  view: 'filled',
  className: 'event-card',
  ...props,
  children
})

const EventDate: FC<EventDateProps> = ({ status, startsAt, endsAt }) => {
  const showFullDate = 
    status === BookingStatusEnum.Draft || 
    status === BookingStatusEnum.Requested ||
    status === CulturalEventStatusEnum.Draft

  return $(Text, { variant: 'body-1', color: 'hint' }, 
    startsAt
      ? $(Flex, { direction: 'column' },
          showFullDate && 
            $(FormattedDate, { value: startsAt, day: '2-digit', month: 'long' }),
          $(Flex, null,
            $(FormattedDate, { value: startsAt, timeStyle: 'short' }),
            endsAt &&
              $(Fragment, null, ' – ', $(FormattedDate, { value: endsAt, timeStyle: 'short' }))
          )
        )
      : $(FormattedMessage, ({ id: 'culturalEvent.noDate' })))
}

const StatusChip: FC<{ status: CulturalEventStatusEnum | BookingStatusEnum }> = ({ status }) => {
  const formattedStatus = Object.keys(CulturalEventStatusEnum).find(
    (key) => key.toLowerCase() === status.toLowerCase()
  )
  const theme = status === 'CANCELED' ? 'danger' : 'unknown'

  if (status === 'REQUESTED')
    return $(Label, { theme: 'warning', className: 'status-chip' },
      $(FormattedMessage, { id: `booking.status.${status}` }))

  if (status === 'ARCHIVED' || status === 'CANCELED')
    return $(Label, { theme, className: 'status-chip' },
      $(FormattedMessage, { id: `culturalEvent.status.${formattedStatus}` as MessageIds }))
}

const GuestsChip: FC<{ guestsCount: number }> = ({ guestsCount }) =>
  $(Label, { 
    theme: 'normal', 
    className: 'status-chip' 
    },
    $(Flex, { 
      alignItems: 'center', 
      gap: 1 
      },
      $(Icon, { data: Persons }),
      $(Text, null, guestsCount)))

type EventDateProps = {
  startsAt: Booking['startsAt'] | CulturalEvent['startsAt']
  status: Booking['status'] | CulturalEvent['status']
  endsAt?: CulturalEvent['endsAt']
}

export default CalendarEventListItemIteratee
