import TextField from 'components/FormikFields/TextField'
import FormRow from 'components/FormRow'
import { MessageIds } from 'components/IntlProvider'
import MarkdownNote from 'components/MarkdownNote'
import { Formik, FormikValues } from 'formik'
import { LegalFragment, useUpdateLegalMutation, useZiferblatLegalQuery } from 'queries'
import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { DataProps } from '..'
import Container from '../Container'
import SaveButton from '../SaveButton'
import { FormRowDirection } from '@gravity-ui/components/build/esm/components/FormRow/types'
import { useLayoutContext } from '@gravity-ui/uikit'

const Legal: FC<DataProps> = ({ id }) => {
  const { data, loading } = useZiferblatLegalQuery({ variables: { id }})
  const [mutate] = useUpdateLegalMutation()
  const legal = data?.ziferblat?.juridical[0]
  const { locale } = useIntl()
  const { activeMediaQuery } = useLayoutContext()
  const direction = activeMediaQuery === 's' ? 'column' : 'row'

  if (loading) return null
  
  const initialValues: InitialValuesType = {
    address: legal?.address || '',
    confidentiality: legal?.confidentiality || '',
    name: legal?.name || '',
    oferta: legal?.oferta || '',
    payment: legal?.payment || '',
    tin: legal?.tin || '',
	}

  const onSubmit = (values: FormikValues) => {
    mutate({
      updateQueries: {
        ZiferblatLegal: (prev, { mutationResult }) => {
          const juridical = [mutationResult.data?.insertZiferblatJuridicalI18nOne]
          const result = { ziferblat: { ...prev.ziferblat, juridical }}
          return result
        },
      },
      variables: { 
        object: {
          ziferblatId: id, 
          lang: locale,
          ...values 
        }}
    })
  }

  return $(Formik, { 
    initialValues, 
    onSubmit, 
    validateOnBlur: true,
    enableReinitialize: true
    },
    $(Container, null,
      $(LegalItem, { name: 'name', direction }),
      $(LegalItem, { name: 'address', direction }),
      $(LegalItem, { name: 'tin', direction }),
      $(LegalItem, { name: 'oferta', markdown: true, direction }),
      $(LegalItem, { name: 'confidentiality', markdown: true, direction }),
      $(LegalItem, { name: 'payment', markdown: true, direction }),
      $(SaveButton)))
}

const LegalItem: FC<{ 
  name: string, 
  markdown?: boolean
  direction: FormRowDirection
}> = ({ name, markdown, direction }) =>
  $(FormRow, {
    key: name,
    label: `settings.legal.${name}` as MessageIds, 
    fallback: name,
    direction
    },
    $(TextField, { 
      name, 
      maxRows: 10, 
      note: markdown 
        ? $(MarkdownNote) 
        : undefined 
    }))

type InitialValuesType = Record<
	keyof Omit<LegalFragment, '__typename' | 'lang' | 'id'>,
	string
>
    
export default Legal
