import { dateTime } from "@gravity-ui/date-utils"
import { ArrowDown } from "@gravity-ui/icons"
import { Button, Divider, Flex, Icon, spacing, Text } from "@gravity-ui/uikit"
import ZiferblatContext from "components/ZiferblatContext"
import { createElement as $, createContext, Dispatch, FC, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react"
import { FormattedDate, FormattedMessage } from "react-intl"
import CalendarEventListItemIteratee from "./CalendarEventListItem"
import { CalendarEvent, GroupedEvents } from "./groupCalendarEvents"
import './style.scss'

const GroupEventsItem: FC<GroupedEventsProps> = ({ events }) => {
  const preparedEvents = Object.entries(events).sort().reverse()
  const [ref, setRef] = useState<RefObject<HTMLDivElement> | null>(null)
  const handleScrollToDate = () => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' })
	}

  return $(TodayRef.Provider, { value: { ref, setRef }},
    $('div', { className: 'corner-background' }),
      $('div', { className: 'event-status-container' },
        $(FormattedMessage, { id: `calendarEvents.status.published` }),
        ref &&
          $(Button, { 
            size: 'xs', 
            view: 'flat', 
            style: { display: 'inline' },
            onClick: handleScrollToDate
            }, 
            $(Text, { color: 'hint'},
              $(Flex, { gap: 1, alignItems: 'center' }, 
                $(Icon, { data: ArrowDown }),
                $(FormattedMessage, { id: 'calendar.showToday' }))))),
    preparedEvents.map(([year, month]) => 
      $(EventsThisYear, { key: year, year, month })))
}
    
const EventsThisYear: FC<YearContainerProps> = ({ year, month }) => {
  const isNotCurrentYear = (new Date()).getFullYear() !== parseInt(year, 10)
  const preparedEvents = Object.entries(month).sort().reverse()

  return $('div', null,
    isNotCurrentYear && $('div', { className: 'event-year-container' }, year), 
    preparedEvents.map(([month, dates]) => 
      $(EventsThisMonth, { key: month, dates, isNotCurrentYear })))
}
 
const EventsThisMonth: FC<MonthContainerProps> = ({ dates, isNotCurrentYear }) => {
  const value = new Date(Object.keys(dates)[0])
  
  return $('div', null, 
    $('div', { className: 'events-month-container', style: { top: isNotCurrentYear && '2rem' }},
      $(Text, { style: { fontSize: '16px' }},
        $(FormattedDate, { value, month: 'long' }))),
    Object.entries(dates).map(([date, events]) => 
      $(EventsOnDate, { key: date, date, events, isNotCurrentYear })))
}

const EventsOnDate: FC<EventsProps> = ({ date, events, isNotCurrentYear }) => {
  const isToday = dateTime().isSame(dateTime({ input: date }), 'day')
  const color = isToday ? 'warning' : 'hint'
  const ref = useRef<HTMLDivElement>(null)
  const { name: role } = useContext(ZiferblatContext)
  const ctx = useContext(TodayRef)

  useEffect(() => { 
    if (isToday && ctx) ctx.setRef(ref)
    if (!ref && ctx) ctx.setRef(null)
  }, [ctx, isToday])
  
  useEffect(() => {
    if (role === 'helper') {
      isToday && ref.current?.scrollIntoView({ inline: 'start', block: 'start' })
      window.scrollBy({ top: -2 })
    }
  }, [isToday, role])
  
  return $(Flex, { ref, direction: 'column', gap: 4 }, 
    isToday && $(Divider, { 
      style: {
        width: '100%', 
        borderBlockStart: '2px solid var(--g-color-base-float)', 
        borderRadius: 'var(--g-border-radius-m)',
        zIndex: -2
      }}),
    $(Flex, { className: spacing({ mb: 4 }) },
      $('div', null,
        $('div', { 
          className: 'events-date-container', 
          style: { 
            top: isNotCurrentYear && '56px'
          }},
          $(Text, { 
            color, 
            variant: 'header-2',
            style: { 
              lineHeight: 'var(--g-text-body-1-line-height)'
            }}, 
            $(FormattedDate, {
              value: date,
              day: 'numeric'
            })),
          $(Text, { color }, 
            $(FormattedDate, {
              value: date,
              weekday: 'long'
            })))),
      $('div', { className: 'events-container' }, 
        events.map(CalendarEventListItemIteratee))))
}

const TodayRef = createContext<TodayRefContextType | undefined>(undefined)

type TodayRefContextType = {
  ref: RefObject<HTMLDivElement> | null
  setRef: Dispatch<SetStateAction<RefObject<any> | null>>
}

type GroupedEventsProps = { 
  events: GroupedEvents | []
}

type EventsProps = {
  date: string
  events: CalendarEvent[]
  isNotCurrentYear: boolean
}

type YearContainerProps = {
  year: string, 
  month: Record<string, Record<string, CalendarEvent[]>>, 
}

type MonthContainerProps = {
  dates: Record<string, CalendarEvent[]>, 
  isNotCurrentYear: boolean 
}

export default GroupEventsItem