import { Flex } from "@gravity-ui/uikit"
import Dniwe from "components/Dniwe"
import Empty from "components/Empty"
import { EventsSkeleton, EventsSkeletonWithDrafts } from "components/LoadingElements/LoadingElements"
import { CalendarQuery } from "queries"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import { CalendarEventProps } from ".."
import CalendarEventListItemIteratee from "./CalendarEventListItem"
import GroupEventsItem from "./GroupEventsItem"
import fetchMoreCalendarEvents from "./fetchMoreCalendarEvents"
import groupCalendarEvents from "./groupCalendarEvents"
import './style.scss'

const Content: FC<CalendarEventProps> = ({ data, loading, fetchMore }) => {
  if (!data && loading) 
    return $(EventsSkeletonWithDrafts)
  if (!data) return $(Empty)

  const groupedEvents = groupCalendarEvents(data.calendar)
  const lastFetchedEvent = data.calendar.at(-1)?.booking ?? data.calendar.at(-1)?.culturalEvent
  const before = lastFetchedEvent?.startsAt!
  const onReached = () => fetchMoreCalendarEvents(before, fetchMore)
  const eventsAggregate = data.calendarAggregate.aggregate?.count || 0

  return $(Flex, { direction: 'column' },
    $(DraftCulturalEvents, { events: data.calendarDraft }),
    $(GroupEventsItem, { events: groupedEvents }), 
    data.calendar.length < eventsAggregate && 
      $(Dniwe, { onReached, content: $(EventsSkeleton) }))
}

const DraftCulturalEvents = ({ events }: { events: CalendarQuery['calendarDraft'] }) => {
  if (!events.length) return null
  
  return $(Flex, { direction: 'column' }, 
    $('div', { className: 'event-status-container' },
      $(FormattedMessage, { id: `calendarEvents.status.draft` })),
    $('div', { className: 'drafts-container' }, 
      events.map(CalendarEventListItemIteratee)))
}

export default Content