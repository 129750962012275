import { FormRowProps, FormRow as GravityFormRow } from '@gravity-ui/components'
import { useLayoutContext } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import { useIntl } from 'react-intl'
import { MessageIds } from './IntlProvider'

const FormRow: FC<FormRowProps & { label: MessageIds; fallback?: string }> = (
	rawProps
) => {
	const intl = useIntl()
	const { activeMediaQuery } = useLayoutContext()
	const props = { ...rawProps } as FormRowProps
	if (!props.direction) props.direction = activeMediaQuery ? 'column' : 'row'
	props.label = intl.formatMessage({
		id: rawProps.label,
		defaultMessage: rawProps.fallback || undefined,
	})

	return $(GravityFormRow, props)
}

export default FormRow
