import { FormValue } from '@gravity-ui/dynamic-forms'
import { TextInput, TextInputProps, useLayoutContext } from '@gravity-ui/uikit'
import ContactInputWithHost from 'components/ContactInputWithHost'
import FormRow from 'components/FormRow'
import { MessageIds } from 'components/IntlProvider'
import PhoneInput from 'components/PhoneInput'
import { Field, FieldProps, Formik, FormikValues } from 'formik'
import { useAddContactMutation, useZiferblatContactsQuery, ZiferblatContactInsertInput } from 'queries'
import { createElement as $, FC } from 'react'
import { DataProps } from '..'
import Container from '../Container'
import SaveButton from '../SaveButton'

const Contacts: FC<DataProps> = ({ id }) => {
  const { data, loading } = useZiferblatContactsQuery({ variables: { id }})
  const [mutate] = useAddContactMutation()
  const ziferblat = data?.ziferblat
  
  if (!ziferblat || loading) return null

  const contacts = ziferblat.contacts || []
  const ziferblatId = ziferblat.id

  const initialValues = contacts.reduce<Record<string, string>>(
    (acc, { type, value }) => {
      acc[type] = value
      return acc
    }, {})

  const onSubmit = (values: FormikValues) => {
    const objects: ZiferblatContactInsertInput[] = Object.entries(values).map(
      ([type, value]) => ({
        type,
        ziferblatId,
        value
      }))

    mutate({
      updateQueries: {
        ZiferblatContacts: (prev, { mutationResult }) => {
          const contacts = mutationResult.data?.addContacts?.returning ?? []

          return {
            ziferblat: {
              ...prev.ziferblat,
              contacts,
            }
          }
        },
      },
      variables: { objects }
    })      
  }

  return $(Formik, { 
    initialValues, 
    onSubmit, 
    validateOnBlur: true,
    enableReinitialize: true 
    },
    $(Container, null,
      contacts.map(ContactIteratee),
      $(SaveButton)))
}

const ContactIteratee = (props: any) => 
  $(ContactItem, { key: props.id, ...props })

const ContactItem: FC<{ id: string, type: string, value: string }> = ({ id, type }) => {
  const { activeMediaQuery } = useLayoutContext()
  const direction = activeMediaQuery === 's' ? 'column' : 'row'

  return $(FormRow, {
    key: id,
    label: `settings.contacts.${type}` as MessageIds,
    fallback: type,
    direction
    },
    $(Field, {
      name: type,
      validate: (value: string) => validateContact(type, value),
      children: (field: FieldProps) =>
        $(ContactInput, { type, ...field })
     }))
}

const ContactInput: FC<FieldProps & { type: string }> = ({ type, ...props }) => {
  const inputProps: TextInputProps = {
    name: type,
    value: props.field.value,
    onUpdate: (value: FormValue) => props.form.setFieldValue(type, value),
    validationState: props.meta.error ? 'invalid' : undefined
  }

  if (!type) return $(TextInput, inputProps)

  const Input = type === 'phone' 
    ? PhoneInput
    : type === 'email'
      ? EmailInput
      : ContactInputWithHost

  return $(Input, inputProps)
}

const EmailInput: FC<TextInputProps> = ({ onUpdate, ...props }) =>
  $(TextInput, { type: 'email', onUpdate, ...props})

const validateContact = (type: string, value: string) => {
	if (!value) return 'required'

	switch (type) {
		case 'phone': {
			if (value.length < 18) return 'incomplete'
			return /^\+?[1-9][0-9\s\-()]{1,16}$/.test(value) ? undefined : 'invalid'
		}
		case 'email':
			return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? undefined : 'invalid'
		case 'vk':
		case 'telegram':
		case 'instagram':
			if (!value.split('/').at(-1)) return 'invalid'
			return /^https?:\/\/[^\s/$.?#].[^\s]*$/.test(value)
				? undefined
				: 'invalid'
		default:
			return undefined
	}
}


export default Contacts
