import { Card, Flex, spacing, Text, useLayoutContext } from "@gravity-ui/uikit"
import { BookingFragment } from "queries"
import { createElement as $, FC, Fragment } from "react"
import { FormattedNumber, useIntl } from "react-intl"

const CheckinsList: FC<BookingFragment> = ({ bookingCheckins }) => {
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const style = isMobile 
    ? { width: '100%', padding: '.5rem' }
    : { maxWidth: '500px', padding: '.5rem 0' }
  if (!bookingCheckins || !bookingCheckins.length) return null
  
  const factualSum = bookingCheckins.reduce((result, { visit, checkin }) =>
    result += (visit?.sum || checkin?.sum || 0), 0)
  const count = bookingCheckins.length

  return $(Flex, { 
    direction: 'column', 
    gap: 2, 
    style
    }, 
    $(Card, { 
      view: 'filled',
      className: spacing({ px: 4, py: 2 }),
      children: $(Stats, { factualSum, count }) 
    }), 
    bookingCheckins.map(CheckinsListItem))
}

const CheckinsListItem = ({ id, visit, checkin }: BookingFragment['bookingCheckins'][0]) => {
  const name = visit?.name || checkin?.name
  const sum = visit?.sum || checkin?.sum

  return $(Card, { 
    key: id,
    view: 'filled', 
    className: spacing({ px: 4, py: 2 }), 
    children: $(Flex, { gap: 2, alignItems: 'center', justifyContent: 'space-between' },
      $(Flex, { alignItems: 'center', gap: 4 }, 
        $(Text, { variant: 'body-2', wordBreak: 'break-all', ellipsisLines: 2 }, name)),
      $(Text, { color: !!sum ? 'primary' : 'hint' }, 
        $(FormattedNumber, {
          value: sum || 0,
          style: 'currency',
          currency: 'RUB',
          maximumFractionDigits: 0
        }))),
    })
}

const Stats: FC<{ 
  factualSum: number, 
  count: number 
  }> = ({ factualSum, count }) => {
  const intl = useIntl()
  
  return $(Fragment, null,
    $(Flex, { justifyContent: 'space-between' }, 
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.count' })), count),
    $(Flex, { justifyContent: 'space-between' },
      $(Text, null, intl.formatMessage({ id: 'culturalEvent.stats.sum.factual' })),
      $(FormattedNumber, { value: factualSum, style: 'currency', currency: 'RUB', maximumFractionDigits: 0 })))
}

export default CheckinsList