import { createElement as $, ReactElement } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { IconData, useLayoutContext } from '@gravity-ui/uikit'
import useNavItems from 'hooks/useNavItems'
import { MeQuery, useMeQuery } from 'queries'
import { useIntl } from 'react-intl'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { pathEntries } from './paths'

const Main = () => {
  const { activeMediaQuery } = useLayoutContext()
  const component = activeMediaQuery === 's' ? Mobile : Desktop
  const { locale } = useIntl()
  const { data } = useMeQuery({ 
    fetchPolicy: 'cache-first', 
    variables: { 
      domain: window.location.hostname,
      locale
    } 
  })
  const navItems = useNavItems()
  
  return $(component, { routes, navItems, data })
}

const routes = $(Routes, null,
  pathEntries.filter(([path, { disabled }]) => !disabled).map(([path, config]) =>
    $(Route, {
      key: path,
      path: `${path}/*`,
      element: config.element
    })),
  $(Route, { 
    path: '*',
    element: $(Navigate, { to: `/${pathEntries[0][0]}` })
  }))

export type DeviceProps = {
  routes: ReactElement
  navItems: {
    label: string
    onClick: () => void
    selected: boolean
    icon: IconData
    disabled: boolean
    hidden?: boolean
  }[]
  data: MeQuery | undefined
}

export default Main
