import { DateFieldState } from '@gravity-ui/date-components'
import { ValueBase } from '@gravity-ui/date-components/dist/esm/components/types'
import { DateTime, dateTime } from '@gravity-ui/date-utils'
import { Clock } from '@gravity-ui/icons'
import QrCode from '@gravity-ui/icons/QrCode'
import { Button, Flex, Icon, spacing, Text } from '@gravity-ui/uikit'
import { MessageIds } from 'components/IntlProvider'
import NativeTimePicker from 'components/NativeTimePicker'
import ZiferblatContext from 'components/ZiferblatContext'
import { BookingStatusEnum, CheckinsDocument, CheckinsQuery, CulturalEventStatusEnum, useCheckInMutation } from 'queries'
import { createElement as $, FC, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import NameInput from './NameInput'
import QRCodeScanner from './QrCodeReader'
import './style.scss'
import { validate, Validation } from './validation'

const AddCheckin: FC<CheckinsQuery> = ({
  checkins, 
  upcomingCulturalEvents,
  upcomingBookings
}) => {
  const [name, setName] = useState('')
  const [checkedInAt, setCheckedInAt] = useState<DateTime | null>(dateTime())
  const validation = validate(checkins || [], { id: '', name })
  const props = { name, validation }
  const navigate = useNavigate()
  const [qrAdd, setQrAdd] = useState(false)
  const intl = useIntl()
  const { ziferblatId } = useContext(ZiferblatContext)
  const [mutate, { loading }] = useCheckInMutation()
  const disabled = !name || validation === Validation.Exists || checkedInAt! > dateTime() || !checkedInAt
  const showQr = upcomingCulturalEvents?.find(({ tickets }) => tickets.length)

  if (!upcomingBookings || !upcomingCulturalEvents) return null

  const calendarEventIteratee = (props: CalendarEvent) =>
    $(CalendarEventItem, {
      key: props.id,
      onClick: () => onClick(props),
      loading,
      disabled,
      text: props.title || props.name || intl.formatMessage({ id: 'calendarEvent.noName' }),
      startsAt: props.startsAt,
      endsAt: props.endsAt,
      status: props.status,
      type: props.__typename
      })

  const onClick = (props: CalendarEvent | void) => {
    const eventOrBooking = 
      props?.__typename === 'Booking'
        ? { checkinBooking: { data: { bookingId: props.id }}}
        : { attendance: { data: [props?.id].map((culturalEventId) => ({
              culturalEventId
            }))}
          }
    
    const checkinData = props ? eventOrBooking : undefined

    mutate({
      variables: {
        data: {
          ziferblatId,
          name,
          checkedInAt: checkedInAt?.toDate(),
          ...checkinData
        }
      },
      update: (cache, result) => {
        const nextCheckins = [...checkins, result.data?.addCheckin]
          .sort((left, right) => left!.checkedInAt > right!.checkedInAt ? 1 : -1)
        cache.modify({
          fields: {
            checkins: () => nextCheckins
          }
        })
      },
      refetchQueries: [{
        query: CheckinsDocument,
        variables: { ziferblatId }
      }]
    }).then(() => navigate('/checkins'))
  } 

  if (qrAdd)
    return $(QRCodeScanner)

  const titleId = `checkins.add${ upcomingCulturalEvents?.length > 0 ? 'Eventless' : '' }`
  const addButton = $(Button, {
    size: 'xl',
    view: 'action',
    onClick: () => onClick(),
    loading,
    pin: showQr ? 'round-brick' : 'round-round',
    disabled,
    style: {
      flexGrow: 1
    }
    },
    intl.formatMessage({ id: titleId as MessageIds }))
    
  const events = [...upcomingBookings, ...upcomingCulturalEvents]
  
  return $(Flex, {
    direction: 'column',
    gap: 2
    },
    $(NameInput, { setName, ...props }),
    $(NativeTimePicker, {
      format: 'HH:mm',
      size: 'xl',
      isDateUnavailable: (date) => date > dateTime(),
      value: checkedInAt as DateFieldState['value'],
      onUpdate: setCheckedInAt as ValueBase<any>['onUpdate']
    }),
    !!events.length && [...events || []]
      .sort((left, right) => left.startsAt! > right.startsAt! ? 1 : -1)
      .map(calendarEventIteratee),
    !showQr
      ? addButton
      : $(Flex, null,
          addButton,
          $(Button, {
            size: 'xl',
            view: 'outlined',
            pin: 'brick-round',
            onClick: () => setQrAdd(true)
            },
            $(Icon, { data: QrCode }))))
}

const CalendarEventItem: FC<CalendarEventItemProps> = ({
  onClick,
  loading,
  text,
  disabled,
  startsAt
}) => $(Button, {
  size: 'xl',
  view: 'flat',
  className: 'add-button', 
  onClick,
  loading,
  disabled,
  }, 
  $(Text, { 
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
    ellipsis: true,
    ellipsisLines: 1,
    style: { 
      textAlign: 'start', 
      maxWidth: '500px' 
    }}, text), 
  $(Flex, { basis: '50px', alignItems: 'center', gap: 1, className: spacing({ ml: 2 })}, 
    $(Clock),
    $(Text, null, 
      startsAt?.toLocaleTimeString(['ru'], { timeStyle: 'short' }))))

type CalendarEventItemProps = {
  onClick: () => void
  loading: boolean
  disabled: boolean
  text: string
  startsAt: Date | undefined,
  endsAt: Date | undefined,
  status?: CulturalEventStatusEnum | BookingStatusEnum
  type?: 'Booking' | 'CulturalEvent' | undefined
}

type CalendarEvent = {
  __typename?: 'CulturalEvent' | 'Booking' | undefined
  id: string
  title?: string
  name?: string
  startsAt: Date
  endsAt?: Date | undefined
  status?: CulturalEventStatusEnum | BookingStatusEnum
  comment?: string,
  internalComment?: string
}

export default AddCheckin