import { Plus } from "@gravity-ui/icons"
import { Button, Icon, Modal } from "@gravity-ui/uikit"
import useFab from "hooks/useFab"
import { GalleryQuery } from "queries"
import { createElement as $, FC } from "react"
import { Route, Routes, useMatch, useNavigate } from "react-router-dom"
import { ImageItemIteratee } from "."
import Image from "./Image"
import './style.scss'
import UploadFab, { FabProps } from "./UploadFab"

const Desktop: FC<GalleryQuery> = (data) => { 
  useFab($(UploadFab, { Fab }))

  return $('div', { className: 'gallery-images-container' }, 
    data.gallery.map(ImageItemIteratee), 
    $(Edit, data))
}

const Edit: FC<GalleryQuery> = (data) => {
  const match = useMatch('/settings/gallery/:page')
  const navigate = useNavigate()

  return $(Modal, {
    className: 'modal',
    open: !!match?.params.page,
    style: { 
      overflow: 'hidden', 
      height: '100%' 
    },
    onClose: () => navigate('/settings/gallery')
    },
    $(Routes, null,
      $(Route, {
        path: ':id',
        element: $(Image, data)
      })))
}

const Fab: FC<FabProps> = ({ onClick, props }) => 
  $(Button, { 
    view: 'action', 
    size: 'xl', 
    onClick,
    ...props
  }, $(Icon, { data: Plus }))

export default Desktop