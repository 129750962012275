import { CalendarQueryResult } from 'queries'

const fetchMoreCalendarEvents = (
	before: Date,
	fetchMore: CalendarQueryResult['fetchMore']
) =>
	fetchMore({
		variables: { before },
		updateQuery(prev, { fetchMoreResult }) {
			const calendar = mergeUnique(prev.calendar, fetchMoreResult.calendar)
      
			return {
				...fetchMoreResult,
				calendar,
			}
		},
	})

const mergeUnique = (prevEvents: any[], newEvents: any[]) => {
	const existingIds = new Set(prevEvents.map(({ id }) => id))
	return [...prevEvents, ...newEvents.filter(({ id }) => !existingIds.has(id))]
}

export default fetchMoreCalendarEvents
