import { createElement as $, FC, ImgHTMLAttributes } from "react"

const Image: FC<ImageProps> = ({ id, width, height, ...props }) => {
	if (!id) return null
	const src = getImageUrl(id, width, height)

	return $('img', {
    key: id,
    ...props,
		src,
	})
}

export const getImageUrl = (id: string, width: number, height?: number) =>
  `${mediaHost}/resized/${width}/${height ? `${height}/` : ''}${id}`
 
export const getOriginalImageUrl = (id: string) =>
   `${mediaHost}/${id}`

const mediaHost = 'https://media.ziferblat.net'

type ImageProps = {
  id: string;
  width: number
  height?: number
} & ImgHTMLAttributes<HTMLImageElement>

export default Image
