import { CalendarQuery } from 'queries'

const groupCalendarEvents = (
	calendar: Calendar | undefined
): GroupedEvents | [] => {
	if (!calendar) return []
	const grouped: GroupedEvents = {}

	calendar.forEach((event: Calendar[number]) => {
			if (!event || !event.startsAt) return
			const date = new Date(event.startsAt)
			const year = date.getFullYear().toString()
			const month = (date.getMonth() + 1).toString().padStart(2, '0')
			const dateKey = date.toISOString().split('T')[0]

			if (!grouped[year]) grouped[year] = {}
			if (!grouped[year][month]) grouped[year][month] = {}
			if (!grouped[year][month][dateKey]) grouped[year][month][dateKey] = []

			grouped[year][month][dateKey].push(event)
		})

	return grouped
}

type Calendar = CalendarQuery['calendar' | 'calendarDraft']
export type CalendarEvent = Calendar[number]
export type CulturalEvent = NonNullable<CalendarEvent['culturalEvent']>
export type Booking = NonNullable<CalendarEvent['booking']>

export type GroupedEvents = {
	[year: string]: {
		[month: string]: {
			[date: string]: CalendarEvent[]
		}
	}
}

export default groupCalendarEvents
