import { FormRow } from '@gravity-ui/components'
import { DatePicker } from '@gravity-ui/date-components'
import { DateTime } from '@gravity-ui/date-utils'
import { ArrowUpRightFromSquare } from '@gravity-ui/icons'
import { Button, Flex, Icon, Text, TextArea, TextInput, useLayoutContext } from '@gravity-ui/uikit'
import FormattedDuration from 'components/FormattedDuration'
import MarkdownNote from 'components/MarkdownNote'
import NativeTimePicker from 'components/NativeTimePicker'
import ZiferblatContext from 'components/ZiferblatContext'
import { useFormikContext } from 'formik'
import { CompensationCurrencyEnum, CompensationUnitEnum, CulturalEventStatusEnum, useZiferblatInfoQuery } from 'queries'
import { createElement as $, FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import StatusSelect from '../StatusSelect'
import Compensation from './Compensation'
import './style.scss'

const CulturalEventForm: FC = () => {
  const {
    values,
    initialValues,
    handleChange,
    handleBlur,
    touched,
    errors
  } = useFormikContext<Form>()
  const intl = useIntl()
	const { activeMediaQuery } = useLayoutContext()
  const { ziferblatId } = useContext(ZiferblatContext)
  const { data } = useZiferblatInfoQuery({ variables: { ziferblatId }})
  const { citySlug, addressSlug } = data?.ziferblat || {}
  const eventDate = values.startsAt?.format('YYYY/M/D')
  const eventLink = `https://${ZIFERBLAT_HOST}/${citySlug}/${addressSlug}/${eventDate}/${values.slug}`
  const isMobile = activeMediaQuery === 's'
  const onBlur = (name: string) => () => handleBlur({ target: { name }})
  const onChange = (name: string) => <T>(input: T) => handleChange({ target: { value: input, name }})
  const direction = isMobile ? 'column' : 'row'

  return $(Flex, {
      gap: isMobile ? 0 : 4,
      direction: 'column',
    },
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.title' }),
      },
      $(TextArea, {
        value: values.title || '',
        onUpdate: onChange('title'),
        onBlur: onBlur('title'),
        error: touched.title && errors.title,
        placeholder: intl.formatMessage({ id: 'culturalEvent.form.title' })
      })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.dateStart' })
      }, 
      $(DatePicker, {
        value: values.startsAt,
        onUpdate: (date) => {
          onChange('startsAt')(date)
          onChange('endsAt')(date?.add(1, 'hour'))
        },
        format: 'DD.MM.YYYY',
        onBlur: onBlur('startsAt'),
        placeholder: intl.formatMessage({ id: 'culturalEvent.form.dateStart' })
      })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.time' }),
      },
      $(Flex, null,
        $('div', { style: { position: 'relative', width: '50%' }},
          $(NativeTimePicker, {
            format: 'HH:mm',
            pin: 'round-clear',
            value: values.startsAt,
            placeholder: intl.formatMessage({ id: 'culturalEvent.form.timeStart' }),
            onUpdate(value) {
              onChange('startsAt')(value) 
              if (values.endsAt && value) {
                if (value >= values.endsAt)
                  onChange('endsAt')(value?.add(1, 'hour'))
              } 
            }
        })),
        $('div', { style: { position: 'relative', width: '50%' }},
          $(NativeTimePicker, {
            format: 'HH:mm',
            pin: 'clear-round',
            value: values.endsAt,
            placeholder: intl.formatMessage({ id: 'culturalEvent.form.timeEnd' }),
            onUpdate(value) {
              onChange('endsAt')(value)
              // FIXME check edge cases
            }
        }))),
        $(Text, { className: 'cultural-event-form_duration-holder', color: 'hint' },
          values.endsAt && values.startsAt &&
            $('div', { className: 'cultural-event-form_duration-holder_durationTime' },
              $(FormattedDuration, { value: values.endsAt.diff(values.startsAt) / 1000, unit: 'second' })))),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.description' })
      },
      $(TextArea, {
        value: values.publicDescription || '',
        onUpdate: onChange('publicDescription'),
        note: $(MarkdownNote),
        minRows: 2
        })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.form.internalComment' }) 
      },
      $(TextArea, {
        value: values.internalComment || '',
        onUpdate: onChange('internalComment'),
        minRows: 2
        })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.compensation' }), 
      },
      $(Compensation)),
    isMobile && 
      $(FormRow, {
        direction,
        label: intl.formatMessage({ id: 'culturalEvent.status' })
        },
        $(StatusSelect, { isMobile })),
    $(FormRow, {
      direction,
      label: intl.formatMessage({ id: 'culturalEvent.slug' }),
      },
      $(TextInput, {
        value: values.slug || '',
        onUpdate: (value) => onChange('slug')(sanitizeSlug(value)),
        placeholder: intl.formatMessage({ id: 'culturalEvent.slug.placeholder' }),
        validationState: !!errors.slug ? 'invalid' : undefined,
        errorMessage: intl.formatMessage({ id: 'culturalEvent.slug.error' }),
        endContent: initialValues.slug &&
          $(Button, { 
            view: 'flat', 
            pin: 'brick-round',
            onClick: () => window.open(eventLink, '_blank')
            },
            $(Icon, { data: ArrowUpRightFromSquare }))
      })))
  }

const DISALLOWED_CHARACTERS = /[^a-z0-9-+]+/g
const ZIFERBLAT_HOST = 'ziferblat.net'

const sanitizeSlug = (value: string) => 
  value.trimStart()
    .replace(' ', '-')
    .replace(/-+/g, '-')
    .replace(DISALLOWED_CHARACTERS, '')

export type Form = {
  startsAt: DateTime | null
  endsAt: DateTime | null
  title: string
  internalComment: string
  publicDescription: string
  status: CulturalEventStatusEnum
  ziferblatId: string
  slug: string | null
  compensation?: {
    unit: CompensationUnitEnum
    currency: CompensationCurrencyEnum
    amount: number
  }
}

export default CulturalEventForm