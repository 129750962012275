import { TextInput, TextInputProps } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import { useIMask } from 'react-imask'
import { useIntl } from 'react-intl'
import { MessageIds } from './IntlProvider'

const ContactInputWithHost: FC<ContactInputProps> = ({
	name,
	value,
	onUpdate,
	...props
}) => {
	const intl = useIntl()
	const hostname = intl.formatMessage({
		id: `settings.contacts.${name}.hostname` as MessageIds,
		defaultMessage: name,
	})
	const preparedHost = getPreparedString(hostname)

	const { ref } = useIMask<HTMLInputElement>(
		{
			mask: `https://${preparedHost}/{slug}`,
			blocks: {
				slug: {
					mask: /^[a-zA-Z0-9_-]*$/,
				},
			},
			lazy: false,
		},
		{
			defaultValue: value,
			onAccept: (value) => onUpdate?.(value),
		}
	)

	return $(TextInput, { controlRef: ref, ...props })
}

const getPreparedString = (value: string | undefined) =>
	value?.replaceAll('a', '\\a')

type ContactInputProps = TextInputProps

export default ContactInputWithHost
