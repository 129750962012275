import { Flex, Skeleton, spacing } from '@gravity-ui/uikit'
import { createElement as $, FC } from 'react'
import './style.scss'

export const SkeletonCard = (value: number, index: number) =>
  $(Skeleton, {
    key: index,
    style: {
      borderRadius: 'var(--g-border-radius-m)',
      height: '100px'
    }})

const getSkeletons = (value: number) => new Array(value).fill(0).map(SkeletonCard)

const loadingElements = 
  $('div', { className: 'loading-elements' }, 
    getSkeletons(25))

export const gridLoadingElements =
  $('div', { className: 'grid-loading-elements' }, 
    getSkeletons(25))

export const EventsSkeleton: FC<{ 
  days?: number, 
  events?: number 
}> = ({ days = 4, events }) => 
  $('div', { className: 'events' },
    Array(days)
      .fill('')
      .map((_, key) => 
        $(SkeletonsOnDate, { key, events })))

export const EventsSkeletonWithDrafts: FC<{ 
  days?: number, 
  events?: number 
}> = ({ days = 4, events }) => 
  $(Flex, { 
    direction: 'column', 
    gap: 2,
    className: 'events-with-drafts'
    },
    $(Skeleton, { className: 'events_status' }),
    $(DraftEventsSkeleton),
    $(Skeleton, { className: `events_status ${spacing({ m: 4 })}` }),
    $(EventsSkeleton, { days, events }))

const SkeletonsOnDate: FC<{ events?: number }> = ({ events: count }) => {
  const randomCount = Math.floor(Math.random() * 8 + 1)

  return $(Flex, { className: spacing({ mb: 4 })}, 
    $('div', { className: 'events_date' },
      $(Skeleton, { className: 'events_day' }),
      $(Skeleton, { className: 'events_weekday' })),
    $('div', { className: 'events_events-container' }, 
      getSkeletons(count ?? randomCount)))
  }

const DraftEventsSkeleton = () =>
  $('div', { 
    className: 'events_drafts-container' 
  }, getSkeletons(4))

export default loadingElements